import React from "react";
import Footer from "../Pages/Footer";
import AboutMe from "../Pages/AboutMe";
import Home from "../Pages/Home";
import Collaborations from "../Pages/Collaborations";
import Header from "../Pages/Header";
import { Routes, Route } from "react-router-dom";
export default function App() {
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,300,700|Roboto:300,400"
        rel="stylesheet"
        type="text/css"
      />
      <div className="box-wrap">
        <Header />
        <div id="colorlib-page">
          <div id="colorlib-main">
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Publications" element={<Collaborations />} />
                <Route path="/About" element={<AboutMe />} />
              </Routes>
            </main>
            <hr />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
