import React from "react";

export default function Home() {
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col-md-12 col-md-offset-2 col-sm-12 homeImage ">
            <img src={`/images/home.jpg`} classname="rounded" alt="Home" />
          </div>
        </div>
        <div className="col-md-12 col-md-offset-2 col-sm-12 homeHeadText ">
          “In order to write about life first you must live it.” – Ernest
          Hemingway
        </div>
      </div>
    </>
  );
}
