import { useState, useRef, useEffect } from "react";
const CosmosClient = require("@azure/cosmos").CosmosClient;

const config = require("../config");
const options = {
  endpoint: config.endpoint,
  key: config.key,
};

const client = new CosmosClient(options);

async function queryContainer() {
  const querySpec = {
    query: "SELECT items.publicationsLinks FROM items",
  };
  try {
    const { resources: results } = await client
      .database(config.database.id)
      .container(config.container.id)
      .items.query(querySpec)
      .fetchAll();
    for (let queryResult of results) {
      return queryResult.publicationsLinks;
    }
  } catch (e) {
    console.log(e);
  }
}

export default function useFetch(url, datos) {
  const isMounted = useRef(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isMounted.current = true;
    async function init() {
      try {
        let response = await queryContainer();
        if (isMounted.current) setData(response);
      } catch (e) {
        if (isMounted.current) setError(e);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    }
    if (!datos) {
      init();
    }

    return () => {
      isMounted.current = false;
    };
  }, [url, datos]);

  return { data, error, loading };
}

export function Fetch({ url, children }) {
  const { data, loading, error } = useFetch(url);
  return children(data, loading, error);
}
