import React from "react";
import "./styles/animate.css";
import "./styles/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import "./styles/cardLinks.css";
import ReactDOM from "react-dom";
import App from "./Pages/App";
import ErrorBoundry from "../src/ErrorBoundry";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <ErrorBoundry>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundry>,
  document.getElementById("root")
);
