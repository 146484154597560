import React, { useState } from "react";
import useFetch from "../services/useFetch";
import Spinner from "./Spinner";

export default function Collaborations() {
  const [filter, setFilter] = useState("0-Last Collaborations");
  const { data: publication, loading } = useFetch("publication", null);

  function renderFilters(collaborations) {
    let imgName = collaborations.replace(" ", "_").replace(" ", "_") + `.jpg`;
    return (
      <li key={collaborations}>
        <div className="profile-card-2">
          <img
            src={`/images/` + imgName}
            className="img"
            onClick={(e) => setFilters(collaborations)}
            alt="filter"
            key={collaborations}
          />
          <div
            className="profile-username"
            onClick={(e) => setFilters(collaborations)}
          >
            {collaborations.replace(
              "0-Last Collaborations",
              "Latest publications"
            )}
          </div>
        </div>
      </li>
    );
  }
  function setFilters(collaborations) {
    setFilter(collaborations);
    document.getElementById("title").scrollIntoView({
      behavior: "smooth",
    });
  }

  function allowLink(link) {
    if (link) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="link link--thebe link--thebe-jump"
        >
          Jump in!
        </a>
      );
    }
  }
  function renderColabs(collaborations) {
    const { id, name, link, description, publisher } = collaborations;

    return (
      <div key={id} className="row">
        <p className="text-title">- {name}:</p>
        <p className="text-publisher">{publisher}</p>
        <p className="text-description col-11">
          {description} {allowLink(link)}
        </p>
      </div>
    );
  }

  function renderFilterColabs(filter) {
    if (filteredPublications.find((s) => s.category === filter)) {
      return (
        <div key={filter} className="col-12 post animate-box fadeInUp animated">
          <div className="text-center ">
            <h4 id="title">
              {filter.replace("0-Last Collaborations", "Latest publications")}
            </h4>
          </div>
          {filteredPublications
            .filter((s) => s.category === filter)
            .map(renderColabs)}
        </div>
      );
    }
  }

  if (loading) {
    return <Spinner text="Publications"></Spinner>;
  }
  const filteredPublications = filter
    ? publication
        .filter((s) => s.category === filter)
        .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
    : publication.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
  const categorys = [
    ...new Set(
      publication
        .sort((a, b) => a.category.localeCompare(b.category))
        .map((q) => q.category)
    ),
  ];
  if (!publication) {
    return <h1>Run out publications!!</h1>;
  }
  return (
    <>
      <section id="intro">
        <div className="container commonText">
          <div className="row margin-vert-30">
            <div className="col-md-12">
              <div className="portfolio-filter-container margin-top-20">
                <ul className="portfolio-filter">
                  {categorys.map(renderFilters)}
                </ul>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="post-entry">
                  {categorys.map(renderFilterColabs)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
