import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

export default function Header() {
  return (
    <div>
      <header id="fh5co-header">
        <Navbar collapseOnSelect expand="lg">
          <Container>
            <Navbar.Brand
              href="/"
              className="col-md-2 col-xs-11 col-xs-offset-3"
            >
              <span className="col-md-2 col-xs-11 col-xs-offset-3  text-center font-link link link-icon link--thebe">
                Katy.
              </span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="headText col-md-12">
                <div className="row headText ">
                  <div className="col-md-12">
                    <ul className="nav text-center ">
                      <li>
                        <Nav.Link eventKey="Home" href="/">
                          <span>Home</span>
                        </Nav.Link>
                      </li>
                      <li>
                        <Nav.Link eventKey="About" href="/About">
                          <span>About</span>
                        </Nav.Link>
                      </li>
                      <li>
                        <Nav.Link eventKey="Publications " href="/Publications">
                          <span>Publications</span>
                        </Nav.Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* <div className="container-fluid">
        
          <nav className="navbar navbar-default">
            <div className="row">
              <div className="col-md-2 text-center">
                <Link
                  to="/"
                  className="font-link"
                  style={{ textDecoration: "none" }}
                >
                  Katy.</span>
                </Link>
              </div>
            </div>
            <div className="row headText ">
              <div className="col-md-12">
                <ul className="nav text-center">
                  <li>
                    <Link to="/">
                      Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/About">
                      About</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Publications">
                      Publications</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Thoughts">
                      Thoughts</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div> */}
      </header>
    </div>
  );
}
