import React from "react";
export default function AboutMe() {
  return (
    <>
      <section>
        <div className="container commonText">
          <div className="row">
            <div className="col-12 text-center">
              <img
                src={`/images/booksIcon.png`}
                alt="booksIcon"
                className="aboutImages "
              />
              <img
                src={`/images/penIcon.png`}
                alt="penIcon"
                className=" penAboutImage"
              />
              <img
                src={`/images/booksIcon.png`}
                alt="booksIcon"
                className="aboutImages "
              />
              <img
                src={`/images/penIcon.png`}
                alt="penIcon"
                className=" penAboutImage"
              />
              <img
                src={`/images/booksIcon.png`}
                alt="booksIcon"
                className="aboutImages "
              />

              <img
                src={`/images/penIcon.png`}
                alt="penIcon"
                className=" penAboutImage"
              />
              <img
                src={`/images/booksIcon.png`}
                alt="booksIcon"
                className="aboutImages "
              />
              <img
                src={`/images/penIcon.png`}
                alt="penIcon"
                className=" penAboutImage"
              />
              <img
                src={`/images/booksIcon.png`}
                alt="booksIcon"
                className="aboutImages "
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 col-sm-9  col-xs-12 margin-bottom-10">
              <h3 className="padding-top-10 pull-left">Katy Dycus</h3>

              <div className="clearfix"></div>
              <p>
                The "sketches" collected here illustrate various perspectives on
                human life, from prehistoric times to the modern day. Just as
                Charles Dickens sketched scenes, fictional and nonfictional,
                from Victorian London in <em>Sketches by Boz</em>, Katy
                approaches each of her narratives as an opportunity to get close
                to a subject and truly understand it.
              </p>
              <p>
                Katy studied literature and biological sciences at Texas A&M
                University and completed a Master of Letters in British
                literature at University of Glasgow (UK), with a special focus
                on the history of science and technology in the long 19th
                century. Her thesis, "The Art of Concealment: Women, Music, and
                Performance in Three Jane Austen Novels," was inspired by Katy's
                own piano playing and broader interests in cultural memory,
                mental health, and music.
              </p>
              <p>
                For the last 11 years, she has worked as a staff writer for the
                anthropology journal Mammoth Trumpet. In addition, she has
                contributed numerous literary essays, poetry reviews and
                interviews to The Wild Detectives. Her writing also appears in
                Hektoen International, Huffington Post, Glasgow Insight into
                Technology and Science, London School of Economics: Review of
                Books, New Hibernia Review and Lady Science.
              </p>
              <p>
                Book publications include a chapter in{" "}
                <em>Crossing Borders in Gender and Culture </em>
                (Cambridge Scholars Press, 2018): " Scandinavian Models for
                Female Emancipation in Britain: Mobility in Ethel Brilliana Alec
                Tweedie and Mary Wollstonecraft’s Northern Tours; as well as a
                comic in{" "}
                <em>
                  Bibliomanías: Taras, Neuras y Tics de Quienes Leen con Locura
                </em>{" "}
                (Pepitas de Calabaza & The Wild Detectives, 2019).
              </p>
              <p>
                When Katy isn't learning, she likes to hike in the mountains
                surrounding Madrid, or play a worthy opponent in chess.
              </p>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-12 aboutMeImages">
              <img src={`/images/profilePhoto.jpg`} alt="katy" />
            </div>
          </div>
        </div>
      </section>
      <div className="post animate-box fadeInUp animated">
        <section id="services">
          <div className="container">
            <div className="row">
              <div className="col-12 animate-box">
                <div className="service">
                  <h2>Feel free to contact me!</h2>
                  <p>
                    Email:{" "}
                    <a
                      href="mailto:kd.dycus@gmail.com"
                      className="link link--thebe "
                    >
                      kd.dycus@gmail.com
                    </a>
                    <a
                      href="https://www.linkedin.com/in/katy-dycus-266ab3b7/"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="Katy's linkedin"
                    >
                      <img
                        src={`/images/linkedin.png`}
                        className="icon-linkedin"
                        alt="Katy's linkedin"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
