let config = {};

config.endpoint = "https://datablog.documents.azure.com:443/";
config.key =
  "HnekXgzSGkHJNmKWU8t92zCee5K3bAxTyF5VPtPF44PO9RGXbtIhLgKvs4C4KMRP4TXir6ptiqIXACDbqacjnA==";
config.database = {
  id: "blog",
};

config.container = {
  id: "blog",
};

module.exports = config;
