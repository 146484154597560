import React from "react";
import "../styles/spinner.css";
export default function Spinner(props) {
  return (
    <>
      <div className="row">
        <div className="col-12 margin-bottom-10">
          <div className="font-link text-center">{props.text}</div>
        </div>
      </div>
      <div className="row margin-vert-30">
        <span className="loader"></span>
      </div>
    </>
  );
}
