import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="colorlib-footer commonText text-center">
        <p> {new Date().getFullYear()} - Katy's Web</p>
      </div>
    </footer>
  );
}
